import { useState, useEffect } from 'react'
import { config } from '../../config'

const enableLogging = process.env.STAGE !== 'prod'

const loadBraze = () => import('@braze/web-sdk')

const changeBrazeUser = (userId: string) => {
  window.braze.automaticallyShowInAppMessages()
  window.braze.changeUser(userId)
  window.braze.openSession()
}

const useBraze = (
  userId: string,
  country: string,
  pathnameWithQuery: string,
  ajs_anonymous_id: string
) => {
  const [brazeInitialized, setBrazeInitialized] = useState<boolean>(false)
  const [pathname] = pathnameWithQuery.split('?')
  const untrackableInApp = config[country].braze?.untrackableInApp ?? true

  useEffect(() => {
    const anonymousUser = !userId
    if (pathname !== '/' && (untrackableInApp || !anonymousUser)) {
      if (!brazeInitialized) {
        loadBraze().then((braze) => {
          window.braze = braze
          window.braze.initialize(config[country].braze.key, {
            baseUrl: config[country].braze.host,
            enableLogging,
            allowUserSuppliedJavascript: true,
            noCookies: anonymousUser,
          })
          changeBrazeUser(userId || ajs_anonymous_id)
          setBrazeInitialized(true)
        })
      } else {
        changeBrazeUser(userId || ajs_anonymous_id)
      }
    }
  }, [userId, brazeInitialized])

  return brazeInitialized
}

export default useBraze
