import React, { useState } from 'react'
import Cookies from 'js-cookie'
import useOneTrust from './hooks/useOneTrust'
import useBraze from './hooks/useBraze'
import useTracking from './hooks/useTracking'

interface TrackingProviderProps {
  children: React.ReactNode
  applicationId: string
  country: string
  pageKey: string
  pathname: string
}

const TrackingProvider: React.FC<TrackingProviderProps> = ({
  children,
  applicationId,
  country,
  pageKey,
  pathname,
}) => {
  const [userId, setUserId] = useState<string | null>(
    Cookies.get('ajs_user_id')
  )

  const handleChangeUserId = (newUserId: string) => {
    setUserId(newUserId)
  }

  useOneTrust({ handleChangeUserId })
  useBraze(userId, country, pathname, Cookies.get('ajs_anonymous_id'))
  useTracking(applicationId, country, pageKey)

  return children
}

export default TrackingProvider
