import Cookies from 'js-cookie'
import { useState, useEffect } from 'react'

interface UseOneTrust {
  handleChangeUserId: (newUserId: string) => void
}

const useOneTrust = ({ handleChangeUserId }: UseOneTrust) => {
  const setCurrentUserId = useState<string | null>(
    Cookies.get('ajs_user_id')
  )[1]
  const oneTrustLoaded =
    typeof window !== 'undefined' &&
    window.OneTrust &&
    window.OneTrust.OnConsentChanged

  if (process.env.STAGE !== 'prod') console.log('OneTrust: loaded')

  useEffect(() => {
    if (oneTrustLoaded) {
      window.OneTrust.OnConsentChanged(async () => {
        if (process.env.STAGE !== 'prod')
          console.log('OneTrust: consent changed')

        const fullUrl = new URL(
          '/api/auth/consent-change',
          window.location.origin
        )

        await fetch(fullUrl, { method: 'POST' })
        const newAjsUserId = Cookies.get('ajs_user_id')
        setCurrentUserId((prevValue) => {
          if (newAjsUserId !== prevValue) {
            handleChangeUserId(newAjsUserId)
            return newAjsUserId
          }
          return prevValue
        })
      })
    }
  }, [oneTrustLoaded])
}

export default useOneTrust
