/*
  This configuration was generated automatically
  Please do not change it
*/

export const domains = {
  'ar.uat.bees-platform.dev': ['es-AR', 'zh-AR'],
  'be.uat.bees-platform.dev': ['fr-BE', 'nl-BE'],
  'bo.uat.bees-platform.dev': ['es-BO'],
  'br.uat.bees-platform.dev': ['pt-BR'],
  'ca.uat.bees-platform.dev': ['fr-CA', 'en-CA'],
  'cl.uat.bees-platform.dev': ['es-CL'],
  'co.uat.bees-platform.dev': ['es-CO'],
  'de.uat.bees-platform.dev': ['de-DE', 'en-DE'],
  'do.uat.bees-platform.dev': ['es-DO'],
  'ec.uat.bees-platform.dev': ['es-EC'],
  'es.uat.bees-platform.dev': ['es-ES', 'en-ES'],
  'gb.uat.bees-platform.dev': ['en-GB'],
  'gh.uat.bees-platform.dev': ['en-GH'],
  'hn.uat.bees-platform.dev': ['es-HN'],
  'id.uat.bees-platform.dev': ['id-ID'],
  'kr.uat.bees-platform.dev': ['ko-KR'],
  'mx.uat.bees-platform.dev': ['es-MX'],
  'nl.uat.bees-platform.dev': ['nl-NL', 'en-NL'],
  'pa.uat.bees-platform.dev': ['es-PA', 'zh-PA'],
  'pe.uat.bees-platform.dev': ['es-PE'],
  'ph.uat.bees-platform.dev': ['fil-PH', 'en-PH'],
  'py.uat.bees-platform.dev': ['es-PY'],
  'sv.uat.bees-platform.dev': ['es-SV'],
  'th.uat.bees-platform.dev': ['th-TH', 'en-TH'],
  'tz.uat.bees-platform.dev': ['en-TZ', 'sw-TZ'],
  'ug.uat.bees-platform.dev': ['en-UG', 'sw-UG'],
  'us.uat.bees-platform.dev': ['en-US', 'es-US'],
  'us.preprod.bees-platform.dev': ['en-US', 'es-US'],
  'uy.uat.bees-platform.dev': ['es-UY'],
  'za.uat.bees-platform.dev': ['en-ZA'],
}

export const config = {
  AR: {
    segment: {
      key: 'XOJNzXscBqjg3Bmldp5QnW1MefcQkRla',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'c0be0122-01b5-46e0-b29e-d3040ff38769-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '09f4b441-a64e-4276-8232-f484f1d91e4d',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.Quilmes.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  BE: {
    segment: {
      key: 'PF3Ee9HeaF21LQBOgnryRih7JXck1wyR',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '482c34fd-7f1c-497f-8ebf-4a42a997f745-test',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: 'a34fdec7-12f1-4de6-8712-a8594df704a8',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.global.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  BO: {
    segment: {
      key: 'iLa9kagwgtJQTHCH2oiuvhLvx7DX2XT3',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'be935a9c-79e8-469f-964e-e5d74485f59b-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'a658f918-c342-4577-8b63-9bceb49685d1',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.bolivia.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  BR: {
    segment: {
      key: 'pjjXk3RFbZDmlDn5INy50glJmwPWQITd',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'f81003dc-98da-4aea-82ee-8dc94686a6e1-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '583a3e98-b4bf-47f7-a75a-f057054f1a98',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyAKkAKZFvReMSU6rxkxGbiNfCVotS9G4oE',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.parceiro-ambev.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  CA: {
    segment: {
      key: 'KQ1ZL6XNeldKXs4v2BbIuzoeucVAB4qy',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '035defb4-442d-4ba3-8c1e-68ca6e3db7b6-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '921314b7-cd6d-49e6-8f38-1d3a52e7eeb2',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.canada.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  CL: {
    segment: {
      key: 'S5ChaGH9RuBChJwL2YA619qhvlJMHZBB',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '018e6741-7d7a-71ea-8475-7d7ce6acdf85-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '963611fd-12c4-4b05-bdf9-4540d4d8e908',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.chile.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  CO: {
    segment: {
      key: 'baucxmLEJpaTy5nOmnwBbL3q5VcHbFQ6',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '09e5f9b9-3f0e-43ab-8d54-b8ab13006dae-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '3cf53231-2b4b-4806-b702-46a3a16845e4',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyAqdLxsccvvKAFVcM4cfJMM0UkOJhFEt08',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.colombia.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  DE: {
    segment: {
      key: 'q8CeEREMIua90sBUCsW3UpRurFUcJyay',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '64563c9b-749f-49d5-8acf-03958a750e22-test',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: 'b403d932-fe35-48a6-ab34-cc3f9c990a52',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.global.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  DO: {
    segment: {
      key: 'EWItkHoiDuDTi0o2FraZ9lvSkY6MpYuB',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '5ad1670e-def9-4582-adb0-eda7ecf2aece-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '1310bd89-f053-4d47-946b-6e948f1539ef',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.Mi-Cerveceria.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  EC: {
    segment: {
      key: 'p9Mh4A8HhOAsuHCVWih5QHnIdhce9n0R',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'a13d857e-6ecd-4550-8308-b967b81da6d2-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '3195af27-3fff-4a4c-885f-0cefe7e4a553',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyBnc2t_h3fWf626CBLbPUbQuaD1dOAW5Xs',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.ecuador.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  ES: {
    segment: {
      key: '1ryXLoozVrIscagACuoPuRoyWhTnC6tC',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '47045cf5-74f2-4b07-9632-c1fceaea581a-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: '62417548-ae19-4a0f-9823-77f4db1a401f',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.global.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  GB: {
    segment: {
      key: 'pIGE7qmjUaUq3jxfxQjI74gUBXcHVLiL',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '73778fa7-f234-465f-ac52-02ce0e3d6cfc-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: 'e0c7f4de-3baf-447f-a050-4054864d2564',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.unitedkingdom.uat',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.global.uat',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.mellifera.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  GH: {
    segment: {
      key: 'YFheepGcs6oblmyH78d8WrArL4ygomz8',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'fa07e7e8-806d-4986-b7f8-5091b78fa3ea-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '7b25ce93-19b6-44b1-be65-c1cf0e2be287',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.africa.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  HN: {
    segment: {
      key: '7nihCb30DRt4W98ZyH8gdtSNpYbeApAr',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '810a18cd-c922-4a9a-b1c0-3013b5880a23-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '7140fb99-c5c3-415b-8baf-85a30ff8068c',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.honduras.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  ID: {
    segment: {
      key: 'XUqSvoMcycXbsJnydHHHrLMthz7XUk9f',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '9c534e1e-4308-456a-a393-cc1b49adad03-test',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: '3ac84347-9f97-4c6f-b37c-9e433664bf67',
      host: 'sdk.fra-02.braze.eu',
      untrackableInApp: false,
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.indonesia.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  KR: {
    segment: {
      key: 'nCI7XLvB3Ge4yzDrxCdfUKXyJWHs6KmM',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '3112a3fd-72aa-48e3-b470-ae40adfe5ae2',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: '56aacb3e-89f3-40fe-9549-6ecfc104232e',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.southkorea.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  MX: {
    segment: {
      key: 'myjo3aEkiOCvIYw9zkz9osSesaON44Ek',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'e1f85cf4-e18b-4afe-b3dc-a9c4008ec964-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'c6db138e-b5df-4f01-b217-fa0885620a4b',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyDWp9RYy_bvOg4fuznZUkFd3xWvYKhldQY',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.mexico.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  NL: {
    segment: {
      key: 'ungQERzRgTkT0ZIV2EchaFDaUSzP3gJ4',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '842d39d9-dcc2-46e4-8794-94e58b3ee4d6-test',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: 'b022f961-0f05-4ee8-a1ad-1e0ee58501b4',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.global.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  PA: {
    segment: {
      key: 'w9zQV2O3a23zVHyuAcg5QMQTuM3q9jCr',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '95433d3e-1b0a-4ded-b21b-44f348447428-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'b4e34b24-d9f7-42f4-9ef6-e7980be05e4c',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.panama.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  PE: {
    segment: {
      key: 'MekBPILzFzPnZUdybMUqGR0ZtD2sjAP4',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'b7d0f091-dfa5-4ab8-80f9-c4f330e1d610-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '036df39c-82bf-4f3b-a8e9-b89101e49954',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyB00swVoykGzGx7-0jmje7SqIvgOxS7RRM',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.peru.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  PH: {
    segment: {
      key: 'LRDqpLJDA7kbhgxfXyNBd0NfnOYrJswJ',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: 'dbaf3ac8-3ba4-4f2b-88bf-28d1a74e9995-test',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: '09646695-a13c-4c26-abb9-27a62e8b54ff',
      host: 'sdk.fra-02.braze.eu',
      untrackableInApp: false,
    },
    googleapi: {
      key: 'AIzaSyCAJzoO-dDi_nfJ97zX1ZDif_h9ewGYrw0',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.philippines.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  PY: {
    segment: {
      key: 'NWARGUvBALw2gL1L0fergF2qh6qSYyDw',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '3ce6e2f6-9b37-4d63-88b8-a391f79b76c0-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '7bf2ea95-918b-45a2-9c43-67ef433affb4',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.paraguay.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  SV: {
    segment: {
      key: '1mEFokKITlUMtwziLaYwN1V32yjWIgaX',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'c8108c51-52c7-4b8b-99e2-039ffea21a16-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '56ca2bfb-6e85-47b1-92dc-2ab25508eabf',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.elsalvador.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  TH: {
    segment: {
      key: 'AqGVDILgkLj1yI6rAkBqM8ETyhMbw29t',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '018dccde-149a-798a-ad64-8693c7e36588-test',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: '69c129ef-da02-4248-9838-62b5483ff9f5',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.thailand.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  TZ: {
    segment: {
      key: 'TfdFTtCCwh0Bq5XTqvMZcj18g8t6sGKH',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'ca17b4d6-42c2-4f75-93ad-216f49909a0e-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'cd11af39-f110-44b3-a4b0-aca267f27837',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.africa.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  UG: {
    segment: {
      key: 'iupAsxa2iWDc1AUXsnpDcWpWBY9twqTT',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'fa07e7e8-806d-4986-b7f8-5091b78fa3ea-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '308dfc91-3471-47a3-a6e0-1f0d5faf1855',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.africa.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  US: {
    segment: {
      key: 'paRPnIAY5MY5VFChmX9OnhbZPAyO7PQE',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'f86d9220-9a08-470e-b71d-fb1c28df4426-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'c74f5062-ceb7-4aa9-a07d-bb8657e137c6',
      host: 'sdk.iad-06.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.unitedstates.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  UY: {
    segment: {
      key: 'QJZzqsdp1SBoTEcvCf52lRqfWlcSklzB',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '4d4a3223-1c58-4f90-b642-579063b8e553-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'c02b595e-316f-4463-b35d-a0c1335b7473',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.uruguay.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
  ZA: {
    segment: {
      key: 'RrdjVnzBRqahrw5ApQNyh9e4xdWrS1D3',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '049d3c28-8276-42e9-80d1-837fb542b89d-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '0f3ac083-f2b3-4efe-866a-56aacdb13b2f',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.sab-connect.uat',
            paths: ['*'],
          },
        ],
      },
    },
  },
}

export { default as dependencies } from './nfa-dependencies'
