import { useEffect } from 'react'
import Cookies from 'js-cookie'
import { routeInitialize } from '@bees-web/nfa-interactive-global/actions/page'
import { clearPersistStorage } from '../../utils/storage'
import anonymizeProperties from '../../segment/middlewares/anonymize'
import Router from 'next/router'
import { routeChangeHandler } from '@bees-web/nfa-interactive-global/utils'
import store from '../../core/store'

const version = process.env.BUILD_BUILDID

const addNewRelicAttribute = (key: string, value: any) => {
  const newRelicAvailable =
    process.env.NODE_ENV !== 'development' &&
    !!process.env.NEW_RELIC_BROWSER_LICENSE_KEY &&
    !!process.env.NEW_RELIC_APPLICATION_ID &&
    !!window.NREUM

  if (key && newRelicAvailable) {
    window.NREUM.setCustomAttribute(key, value)
  }
}

const getReferrerUrl = (referrer: string): URL | null => {
  return referrer && referrer !== '' ? new URL(referrer) : null
}

const useTracking = (
  applicationId: string,
  country: string,
  pageKey: string
) => {
  const { dispatch } = store

  useEffect(() => {
    const removeImpevaCookies = () => {
      if (process.env.STAGE !== 'prod') {
        const cookieList: string[] = []
        Object.keys(Cookies.get()).forEach((cookie) => {
          if (cookie.includes('incap_ses')) {
            cookieList.push(cookie)
          }
        })
        cookieList.forEach((cookie) => Cookies.remove(cookie))
      }
    }

    removeImpevaCookies()
    clearPersistStorage(version)
    addNewRelicAttribute('beesApplication', applicationId)
    anonymizeProperties(country)

    const referrer = document.referrer
    const origin = window.location.origin
    const pathname = window.location.pathname

    if (referrer) {
      dispatch(routeInitialize(origin, pathname, referrer))
    } else {
      const referrerUrl = getReferrerUrl(referrer)
      dispatch(
        routeInitialize(
          origin,
          pathname,
          referrerUrl ? referrerUrl.pathname : ''
        )
      )
    }
  }, [dispatch, applicationId, country])

  useEffect(() => {
    addNewRelicAttribute('journey', pageKey)
  }, [pageKey])

  useEffect(() => routeChangeHandler(Router, store), [store])
}

export default useTracking
